// Importazione libreria SCSS di bootstrap-italia
@import './custom_boostrap-italia';
@import './font';
@import "~@ng-select/ng-select/themes/default.theme.css";

body {
    font-size: 16px;
    line-height: 1.5;
    width: 100%;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

::-webkit-scrollbar-thumb {
    background: #aaaaaa;
    border: 0px none #fff;
    border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
    background: #aaaaaa;
}

::-webkit-scrollbar-thumb:active {
    background: #aaaaaa;
}

::-webkit-scrollbar-track {
    background: #eeeeee;
    border: 0px none #8080ff;
}

::-webkit-scrollbar-track:hover {
    background: #eeeeee;
}

::-webkit-scrollbar-track:active {
    background: #eeeeee;
}

::-webkit-scrollbar-corner {
    background: transparent;
}


ul {
    list-style: none;
}

.list-inside {
    list-style: inside;
}

// Height
.full-height {
    height: 100%;
}

.max-height-500 {
    max-height: 500px;
}

// Width
.full-width {
    width: 100%;
}

.max-width-170 {
    max-width: 170px;
}

.max-width-300 {
    max-width: 300px;
}

.min-width-110 {
    min-width: 110px;
}

.min-width-115 {
    min-width: 115px;
}

.min-width-125 {
    min-width: 125px;
}

.min-width-140 {
    min-width: 140px;
}

// Margin
.m-0 {
    margin: 0 !important;
}

.m-t-32 {
    margin-top: 32px;
}

.m-t-16 {
    margin-top: 16px;
}

.m-b-16 {
    margin-bottom: 16px;
}

.m-r-16 {
    margin-right: 16px;
}

.m-l-16 {
    margin-left: 16px;
}

.m-l-5 {
    margin-left: 5px;
}

.card {
    border-radius: 10px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    transition: 0.3s;
    background-color: #ffffff;
    padding: 16px;
}

.cursor-pointer {
    cursor: pointer;
}


.custom-position-notification {
    right: initial;
    left: 16px;
    z-index: 9999;
}

//FONT
.font-italic {
    font-style: italic;
}


.disabled {
    opacity: 0.65;
}

.rotate-180 {
    transform: rotate(180deg);
}
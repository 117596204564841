@import 'bootstrap-italia/src/scss/bootstrap-italia.scss';


$v-gap: 8px;

// :focus:not(.focus--mouse),
// %focus {
//   border-color: $focus-outline-color !important;
//   box-shadow: 0 0 0 2px $focus-outline-color !important;
//   outline: none !important;
// }

.autocomplete__option--focused, .autocomplete__option:hover {
    color: var(--bs-primary);
}

.btn-icon-custom {
    box-shadow: none !important;
    padding: 0 !important;

    svg {
        width: 38px;
        height: 38px;
        fill: var(--bs-secondary);
    }
}

.hiden_arrows_carousel {
    &.it-carousel-wrapper .splide__arrows {
        display: none !important;
    }
}

.it-carousel-wrapper {
    &.it-carousel-landscape-abstract-one-col-arrow-visible {
        .splide__arrows {
          position: absolute;
          top: 50%;
          left: -$v-gap * 5;
          right: -$v-gap * 5;
          display: flex;
          z-index: 1;
          justify-content: space-between;
          pointer-events: none;
          .splide__arrow {
            width: $v-gap * 5;
            height: $v-gap * 5;
            background: var(--bs-primary);
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            pointer-events: all;
            svg {
              fill: #fff;
              width: $v-gap * 2;
              height: $v-gap * 2;
            }
            &.splide__arrow--prev {
              transform: rotate(180deg);
            }
            &[disabled] {
              background: #d8d9da;
            }
          }
        }
      }
}

.autocomplete-main {
    .autocomplete__wrapper {
        max-height: 32px;
        input {
            border-radius: 10px !important;
            border: none;
            background-color: #ffffff;
            max-height: 32px;
        }
    }
}

.it-footer {
    // position: absolute;
    // width: 100%;
    // bottom: 0;
}

@media(min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}